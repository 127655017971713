





















































































































































































import Vue from "vue";
import * as firebase from "firebase/auth";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import { User } from "@/types";
import { DATE_REGEX } from "@/util/constants";
import { setAuth } from "@/util/auth";

const getAge = (d: string) => {
  // d format: 14/11/2004
  if (!d) return 0;
  if (d.split("/").length !== 3) return 0;
  const dob = `${d.split("/")[2]}-${d.split("/")[1]}-${d.split("/")[0]}`;
  const diff = Date.now() - new Date(dob).getTime();
  const age = new Date(diff);
  return Math.abs(age.getUTCFullYear() - 1970);
};

const AHIDI_DOMAIN = process.env.VUE_APP_AHIDI_DOMAIN;

export default Vue.extend<any, any, any, any>({
  name: "SignUp",
  data: () => ({
    valid: false,
    dateDialog: false,
    agreedToTerms: false,
    termsRules: [(v: boolean) => v || "Agree to terms"],
    password: "",
    passwordRules: [
      (v: string) => !!v || "Password is required",
      (v: string) =>
        v.length >= 6 || "Password must be greater than 6 characters",
    ],
    confirmPassword: "",
    email: "",
    emailRules: [
      (v: string) => !!v || "E-mail is required",
      (v: string) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    displayName: "",
    nameRules: [(v: string) => !!v || "Name is required"],
    dob: "",
    dobRules: [
      (v: string) => !!v || "Date of Birth is required",
      (v: string) => DATE_REGEX.test(v) || "Date of birth must be valid format",
      (v: string) => getAge(v) >= 18 || "Minimum age is 18 years",
    ],
    gender: "",
    genderRules: [(v: string) => !!v || "Gender is required"],
    user: undefined as undefined | User,
    showDialog: false,
    country: "",
    countryRules: [(v: string) => !!v || "Country is required"],
    businessNameRules: [(v: string) => !!v || "Business name is required"],
    dateFormatted: "",
    dateMenu: false,
    submitted: false,
    isPasswordField: true,
  }),
  computed: {
    passwordConfirmationRules() {
      return [
        (v: string) => !!v || "Password Confrimation is required",
        () => this.password === this.confirmPassword || "Password must match",
      ];
    },
    eighteenYearsAgo() {
      return moment().subtract(18, "years").toISOString();
    },
    countries() {
      if (AHIDI_DOMAIN.includes(".co.ke")) return ["Kenya"];
      if (AHIDI_DOMAIN.includes(".com.au")) return ["Australia"];
      return ["Australia", "Kenya"];
    },
  },
  mounted() {
    this.dob = this.eighteenYearsAgo;
  },
  methods: {
    validateSignUp() {
      const valid = (
        this.$refs.signUpForm as Element & { validate: () => boolean }
      )?.validate();

      if (valid) {
        this.register();
      }
    },
    register() {
      this.submitted = true;
      this.$store.dispatch("setLoadingRequest", true);
      firebase
        .createUserWithEmailAndPassword(
          firebase.getAuth(),
          this.email,
          this.password
        )
        .then((data) => data.user.getIdToken())
        .then((token) => {
          let date = this.dateFormatted.split("/");

          const dob = new Date(date[2], date[1] - 1, date[0]).toISOString();
          this.$store.dispatch("setLoadingRequest", false);
          setAuth({ token }, false);
          this.$store
            .dispatch("register", {
              displayName: this.displayName,
              dob: dob,
              gender: this.gender,
              preferences: ["none"],
              country: this.country,
            })
            .then((user) => {
              if (user) {
                this.user = user;
                this.showDialog = true;
                this.submitted = false;
              }
            });
        })
        .catch((error) => {
          this.$store.dispatch("setLoadingRequest", false);
          this.$store.dispatch("setToast", {
            title: "Request Failed!",
            type: "error",
            text: error.message,
          });
          this.submitted = false;
          console.log(error.message);
        });
    },
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
    parseDate(date: string) {
      if (!date) return null;
      let d: string | string[] = date.split("/");

      d = `${d[2]}-${d[1]}-${d[0]}`;

      return moment(d).tz(tz).format("YYYY-MM-DD");
    },
  },
});
